.header {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  height: 5rem;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0 0;
  background-color: #172533;
  background-image: url("../../public/assets/icon.png");
  background-position: left 5% bottom 50%;
  background-repeat: no-repeat;
  color: white;
}
.container{
 position: relative; 
 
}

.conversation {
  position: absolute; 
  top: auto;
  width: 100%;
  height: calc(100vh - 150px);
  background-color: #ededed;
  overflow: auto;
  word-wrap: break-word;
}

.intro {
  margin-left: 1rem;
  font-size: large;
}  

.list {
  list-style: none;
  border: 1px solid grey;
  padding: 0.5rem;
  margin: 1rem;
  width: 85%;
  border-radius: 10px 10px 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  line-height: 1.5;

  &:nth-child(2n + 1) {
    background-color: rgba(53, 157, 221, 0.6);
    border-radius: 10px 10px 0 10px;
    margin-left: 35px;
  }
}
.main__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  width: 98%;
  position: absolute;
  height: 2.2rem;
  display: flex;
  justify-content: space-between;
  border: 2px solid rgba(180, 180, 180, 0.5);
  border-radius: 6px;
  margin: 4px;
  bottom: 0;
  &:hover {
    border: 2px solid rgba(70, 208, 243, 0.6);
  }
}

.input {
  text-overflow: ellipsis;
  width: 100%;
  word-wrap: break-word;
  padding: 4px;
  border: hidden;
  outline: none;
  border-radius: 5px 0 0 5px;
}

.send {
  background: rgb(53, 157, 221);
  border-radius: 5px;
  padding: 0.25rem;
  border: none;
  color: #ededed;
  border-radius: 0 5px 5px 0;
  outline: none;
  opacity: 0.8;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
