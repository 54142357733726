* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  font-family: "Jost", sans-serif;
  font-size: 14px;
  margin:0 auto;
  width: 100%;
  max-width: 500px;
  height: 100vh;
  border: 2px solid grey;
  border-radius: 10px;
  background-color: #ededed;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}
